import NextImage from 'components/foundation/non-sitecore/NextImage';
import { Field, ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import ResultImage from 'components/atomic-components/ResultImage';
type ImageCardProps = {
  image: ImageField;
  cardtype: string;
  byPassNextJsImageOptimization?: Field<boolean>;
  imageCDNUrl?: Field<string>;
};
const ImageHeader = (props: ImageCardProps): JSX.Element => {
  const editorActive = isEditorActive();
  return (
    <>
      {props.cardtype == 'standard' &&
        (!editorActive && props.byPassNextJsImageOptimization?.value ? (
          <div className="aspect-[2/1] relative overflow-hidden">
            <span className="box-border max-w-full block absolute object-cover min-w-full h-full">
              {props.imageCDNUrl?.value ? (
                <ResultImage
                  src={props.imageCDNUrl.value ?? ''}
                  alt={props.image?.value?.alt as string}
                  className="min-w-full min-h-full col-span-12 w-[100%] object-center lg:col-span-8 xl:col-span-7 object-cover"
                />
              ) : (
                props.image?.value?.src != null &&
                props.image?.value?.src != '' && (
                  <ResultImage
                    src={props.image?.value?.src ?? ''}
                    alt={props.image?.value?.alt as string}
                    className="min-w-full min-h-full col-span-12 w-[100%] object-center lg:col-span-8 xl:col-span-7 object-cover"
                  />
                )
              )}
            </span>
          </div>
        ) : (
          <div className="aspect-[2/1] relative overflow-hidden">
            <NextImage field={props.image} layout="fill" />
          </div>
        ))}
      {props.cardtype == 'feature' &&
        (!editorActive && props.byPassNextJsImageOptimization?.value ? (
          <div className="aspect-[4/3] xl:col-span-8 relative overflow-hidden">
            <span className="box-border max-w-full block absolute object-cover min-w-full h-full">
              {props.imageCDNUrl?.value ? (
                <ResultImage
                  src={props.imageCDNUrl.value ?? ''}
                  alt={props.image?.value?.alt as string}
                  className="min-w-full min-h-full col-span-12 w-[100%] object-center lg:col-span-8 xl:col-span-7 object-cover"
                />
              ) : (
                props.image?.value?.src != null &&
                props.image?.value?.src != '' && (
                  <ResultImage
                    src={props.image?.value?.src ?? ''}
                    alt={props.image?.value?.alt as string}
                    className="min-w-full min-h-full col-span-12 w-[100%] object-center lg:col-span-8 xl:col-span-7 object-cover"
                  />
                )
              )}
            </span>
          </div>
        ) : (
          <div className="aspect-[4/3] xl:col-span-8 relative overflow-hidden">
            <NextImage field={props.image} layout="fill" />
          </div>
        ))}
    </>
  );
};

export default ImageHeader;
